
@font-face{
  font-family: 'Manrope';
  src:url('./assets/fonts/Manrope-VariableFont_wght.ttf')
}


*{
  font-family: Manrope;
  font-size: 1rem;
  font-weight: 600;
  color: #24282C;
}


.bg-primary{
  background-color: #D3F36B !important;
}
.bg-main{
  background-color: #F1EDE7 !important;
}

 .bg-secondary{
  background-color: #22331D !important;
 }
 .text-primary{
  color:#24282C !important;
 }
.text-secondary{
 color:#939393 !important;
}
.h-100perc{
  height: 100%;
}
.text-main{
  color:#F1EDE7 !important;
}

.text-success{
  color: #7DB36C !important;
}

.text-danger{
  color:  #F37624 !important;
}
.text-danger-secondary{
  color:#FDA29B !important;
}
.text-add{
  color: #D3F36B;
}
.extra-bold{
  font-size: 1.5rem;
  font-weight: 800;
}
.modal-content{
  border-radius: 20px !important;
}
.semi-bold{
  font-size: 1.25rem;
  font-weight: 800;
}
.ml-24{
  margin-left: 24px !important;
}

.left-auto{
  left: auto !important;
}
.right-0{
  right: 0;
}
.mt-6{
  margin-top: 6px;
}

 
.w-13{
  width: 13px !important;
}
.h-13{
  height: 13px !important;
}

.rounded-50{
  border-radius: 50% !important;
}
.w-24{
  width: 24px !important;
}
.modal-body{
  padding: 0 !important;
}
.bg-active{
  background-color: #1AB533;
}


.s-regular-small{
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}
.w-328{
  width: 328px !important;
}
.s-regular-semi{
  font-size: 0.9rem;
  font-weight: 600;
}
.s-regular-semi-bold{
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}
.s-regular-medium{
  font-size: 1rem;
  font-weight: 500;
}
.w-169{
  width: 50px !important;
}
.input-border-primary{
  border-color: #E3E3E3!important;
}
.border-bottom-secondary{
  border-bottom: 1px solid #F1EDE7 !important;
}
.border-secondary{
  border: #F7F6F0 1px solid !important;
}
.border-bottom-success{
  border-bottom:#F7F6F0 1px solid !important;
}

.border-success{
  border: 2px solid #D3F36B !important  ;
}
.line-height{
  line-height: 24px;
}
.btn-outline-dark{
 outline:#22331D !important;
 border: #22331D 1px solid !important ;
 background-color: #FFFFFF !important;
}
.btn-outline-main{
  outline:#22331D !important;
 border: #22331D 1px solid !important ;
 background-color: #F1EDE7 !important;
}
.pr-24{
  padding-right: 24px !important;
}

.s-regular{
  font-size: 1rem;
  font-weight: 600;
}

.s-regular-normal{
  font-size: 1rem!important;
  font-weight: 400;
}
.s-regular-large{ 
  font-weight: 400;
  font-size: 2.2rem;
}

.custom-input-date{
  font-size: 1 rem !important;
  font-weight: 400 !important;
  color: #939393 !important;
}


.s-regular-xsmall{
  font-weight:400  !important;
  font-size: 0.75rem !important;
}
.s-bold-main{
  font-size: 1.25rem;
  font-weight: 700;
}

.no-arrow::after {
  display: none !important;
}
.m-24{
  margin-top: 24px;
}
.s-regular-main{
  font-size: 1rem;
  font-weight: 700;
}
.w-6{
  width: 20px;
}





.h-100{
  height: 100vh !important;
}
.h-80perc{
  height:450px !important;
}
.height-50px{
  height: 50px !important;
}
.h-40{
  height: 40px;
  
}
.h-100px{
  height: 100px !important;
}
.w-100px{
  width: 100px !important;
}
.w-40{
  width: 40px;
}

.height-42px{
  height: 42px !important;
}
.height-80px{
  height: 80px !important;
}
.h-35{
  height: 35%;
}
.h-70{
  height: 80vh;
}
.btn-primary{
  background-color:#D3F36B !important;
}
.h-256{
  height: 256px !important ;
}
.btn-secondary{
  background-color: #22331D !important;
}
.width-1px{
  width: 1px;
}
.border-danger{
  border-color:#FDA29B !important;
}
.bg-inactive{
  background-color:#FDA29B ;
}

.error-msg{
  color: #F97676 !important;
}
.border-none{
  border: none !important;
}

.decoration-none{
  text-decoration: none;
}
.cursor-pointer{
  cursor: pointer;
}
.rounded-20{
  border-radius: 20px !important;
}

.nav-link.active{
  background-color: #D3F36B !important;
  border-radius: 5px !important;
  border:none !important;
  
}
.nav-link{
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #24282C !important;
}
.p-4-12{
  padding: 4px 12px 4px 12px;
}
.rounded-16{
  border-radius: 16px;
}
.p-40{
  padding: 40px;
}
.p-12-0{
  padding: 12px 0px 12px 0px !important;
}
.w-1px{
  width: 1px;
}
.w-93px{
  width: 93px !important;
}
 .login-gradient-tb{
  background:linear-gradient(#C4C6C9,#737373  ) ;
 }
 .login-gradient-bt{
  background:linear-gradient(to bottom,#737373, #C4C6C9  );
 }
.border-bottom-primary{
  border-bottom: #D3F36B 1px solid !important;
}
.border-bottom-primary-bold{
  border-bottom: #D3F36B 2px solid !important;
}
.border-black{
  border: 1px solid #23331D ;
}
.outline-0{
  outline: none !important;
}
.h-44{
  height: 44px;
}
.w-177{
  width:177px;
}
.w-195{
  width: 195;
}
.w-120{
  width: 120px;
}
.right-50{
  right: 50%;
}
.right-50-left{
  right: -75%;
  /* left: 55%; */
  
}
.w-20{
  width: 20px !important;

}
.h-20{
  height: 20px !important;
}
.h-150{
  height: 150px;
}
.rounded-10{
  border-radius: 10px !important;
}
.mt-40{
  margin-top: 40px;
}
.p-10{
  padding: 10px;
}
.pr-12{
  padding-right: 12px;
}
.pl-12{
  padding-left: 12px !important;
}

.p-16-10{
  padding: 16px 10px;
}
.p-10-16{
  padding: 10px 16px;
}
.h-90px{
  min-height: 90px;
}
.p-24{
  padding: 24px;
}
.gap-24{
  gap: 24px;
}
.gap-10{
  gap: 10px !important;
}
.page-item a:hover{
  border-radius: 10px !important;
}

.rounded-6{
  border-radius:0px !important;
}
.py-24{
  padding-top: 24px;
  padding-bottom: 24px;
}
.mr-14{
  margin-right: 14px !important;
}

.page-link:focus{
  background-color:white !important;
  box-shadow: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
/* display: none; */
-webkit-appearance:'none';
  background-image: url('./assets/icons/calendar2.svg');
  cursor: pointer;
  width: 24px;
  height: 100%;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.page-item a{
  color:#24282C !important;
  border: none;
  min-width: 40px;
  height: 40px;
  border-radius: 10px !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active .page-link{
  border-radius: 8px !important;
  width: 40px !important;
  height: 40px;
  color: #24282C;
  background-color: #D3F36B;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0 !important;
}

.height-200{
  height: 150px !important;
}

.w-101{
  width: 101px !important;
}
.accordion-button:focus {
  box-shadow: none !important;
  color:#24282C !important;
  background-color:white !important;
  border-color: #F7F6F0 !important;
}
.accordion-button{
  border-radius: 10px !important;
  color:#24282C !important;
  background-color:white !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.accordion-item .accordion-button:not(.collapsed)::after{
  background-image: url("../src/assets/icons/arrowup.svg");
}
.accordion-item{
  border-radius:10px !important;
}
.accordion-body{
  padding: 0px !important;
}
/* .form-control{
  padding: 0px !important;
} */

.mb-24{
  margin-bottom: 24px;
}
.mb-0{
  margin-bottom: 0 !important;
}
.gap-6{
  gap: 6px !important;
}
.pb-24{
  padding-bottom: 24px;
}
.h-365{
  height: 365px !important;
}
/* .w-12{
  width: 120px !important;
} */
.checkbox{
  appearance: none !important;
  width: 20px !important;
  height: 20px !important;
  background-color:white;
  border: #E3E3E3 1px solid !important;
  border-radius: 6px !important;
}
.unpaid{
  background-color: #FFFAEB;
  color: #D3A80D;
}
.paid{
  background-color: #ECFDF3;
  color: #027A48;
}
.partial{
  background-color: #FAECFD;
  color: #8843BE;
}
.checkbox:checked {
  border: #22331D solid 1px  !important;
  box-shadow: none;
  background-color:#F1EDE7 !important;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../src/assets/icons/check.svg') !important;
}
/* .visibility-none{
  visibility: collapse;
} */
.w-2x{
  width: 150% !important;
}
.w-25px{
  width: 20px ;
}
.w-16{
  width: 16px;
}
.h-25px{
  width: 20px;
}

.w-0{
  width: 0px !important;
}
.datepicker-container{
  position: absolute;
}
.h-24{
  height: 24px !important;
}
.mt-24{
  margin-top: 24px !important;
}
.h-250{
  height: 250px !important;
}
.notistack-MuiContent-success{
  background-color:#71DA88 !important;
}

.notistack-MuiContent-error{
  background-color:#FDA29B !important;
}


.MuiSnackbarContent-root.error {
  background-color:#FDA29B !important;
}

.MuiSnackbarContent-root.warning {
  background-color: orange;
}

.MuiSnackbarContent-root.info {
  background-color: blue;
}

.s-xlarge{
  font-size: 120px !important;
  font-weight: 800 !important;
}
.s-large-semi{
  font-size: 24px;
  font-weight: 800;
}
.h-60{
  height: 80% !important;
}




@media screen and (min-width: 768px) {
 div.w-169{
    width: 169px !important;
  }
  div.gap-md-24{
    gap: 24px !important;
  }
}
@media screen and (min-width: 1200px) {
 div.pr-xl-24{
    padding-right: 24px !important;
  }
  div.mt-xl-0{
    margin-top: 0px !important;
  }
}






 
